import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const Video = styled.video`
  width: 100%;
  height: auto;
`

const LazyVideo = ({ src }) => {
  return (
    <div>
      <Video autoPlay muted loop>
        <source src={ src } type="video/mp4" />
        Your browser does not support the video tag.
      </Video>
    </div>
  );
};

LazyVideo.propTypes = {
  src: PropTypes.string,
}

export default LazyVideo;

